import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, expand, Observable, of, reduce, takeWhile, tap } from 'rxjs';
import { ProfileType } from '@core/models/utils.model';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  GRAPH_ENDPOINT = environment.msalAuth.graphEndpoint;

  private groupsSubject = new BehaviorSubject<String[]>([]);
  public groups$ = this.groupsSubject.asObservable();

  private profileSubject = new BehaviorSubject<ProfileType | null>({});
  public profile$ = this.profileSubject.asObservable();

  private readonly _http = inject(HttpClient);

  getAllUsersCompanyPagination() {
    return this._http
      .get<{
        value: ProfileType[];
        '@odata.context': string;
        '@odata.nextLink'?: string;
      }>(
        `${this.GRAPH_ENDPOINT}/users?$expand=manager($select=id,displayName,mobilePhone,officeLocation,userPrincipalName,jobTitle)`
      )
      .pipe(
        expand((response) => {
          return response['@odata.nextLink'] ? this.getDataNextLink(response['@odata.nextLink']) : of(null);
        }),
        takeWhile((response) => response !== null),
        reduce((acc, response) => acc.concat(response.value), [])
      );
  }

  getDataNextLink(nextLink: string): Observable<IResponseApiGraph> {
    return this._http.get<IResponseApiGraph>(nextLink);
  }

  getDirectReportUser(email: string) {
    return this._http.get(`${this.GRAPH_ENDPOINT}/users/${email}/directReports`);
  }

  getDirectReportMe() {
    return this._http.get<{ value: ProfileType[]; '@odata.context': string }>(
      `${this.GRAPH_ENDPOINT}/me/directReports`
    );
  }

  recursive(userId, outputArray, controlBucle) {
    this.getDirectReportUser(userId).subscribe((response: any) => {
      const users = response.value;
      if (users.length > 0) {
        users.forEach((user: any) => {
          let index = controlBucle.findIndex((e) => e.id === user.id);

          if (index === -1) {
            controlBucle.push(user);
            outputArray.next(user);
            this.recursive(user.id, outputArray, controlBucle);
          }
        });
      }
    });
  }

  getMeProfile() {
    return this._http.get<ProfileType>(
      `${this.GRAPH_ENDPOINT}/me?$expand=manager($select=id,displayName,mobilePhone,officeLocation,userPrincipalName,jobTitle)`
    );
  }

  getDataMeProfile() {
    return this._http.get(`${this.GRAPH_ENDPOINT}/me`).pipe(
      tap((response: any) => {
        this.profileSubject.next(response);
      }),
      tap(() => {
        this.getMyGroups().subscribe((groups: any) => {
          if (groups.value.length > 0) {
            const groupD = groups.value.map((group) => group.displayName);
            this.groupsSubject.next(groupD);
          }
        });
      })
    );
  }

  allMyCalendars(userPrincipalName: string) {
    return this._http.get(`${this.GRAPH_ENDPOINT}/users/${userPrincipalName}/calendars`);
  }

  addEventsCalendarMe(bodyEventOutlookCalendar: any) {
    return this._http.post(`${this.GRAPH_ENDPOINT}/me/events`, bodyEventOutlookCalendar);
  }

  getMyGroups() {
    return this._http.get<IResponseApiGraph>(`${this.GRAPH_ENDPOINT}/me/memberOf`);
  }

  getMembersGroups(id: string) {
    return this._http.get<IResponseApiGraph>(
      `${this.GRAPH_ENDPOINT}/groups/${id}/members/microsoft.graph.user?$expand=manager($select=id,displayName,mobilePhone,officeLocation,userPrincipalName,jobTitle)`
    );
  }

  sendMail(body: any) {
    return this._http.post(`${this.GRAPH_ENDPOINT}/me/sendMail`, body);
  }
}

export interface IResponseApiGraph {
  value: unknown;
  '@odata.context': string;
  '@odata.nextLink'?: string;
}
